const additionalSalesDefaultState = {
  assistanceOptions: {
    assistanceId: '',
    zohoId: '',
    customerAcceptation: '',
    axaEmailAlreadySent: false,
    delayActivation: ''
  },
  carbonOffset: {
    offsetId: '',
    zohoId: '',
    customerAcceptation: ''
  },
  voltalis: {
    heaterCount: '',
    callbackDate: '',
    callbackDetail: '',
    zohoId: '',
    isMainResidence: '',
    isHeatedElectricity: '',
    isMoreThan1Radiator: '',
    constructionYear: '',
    customerCallbackAgreement: '',
    customerInstallationAgreement: ''
  },
  garanka: {
    customerAgreement: '',
    offerId: '',
    buildingType: '',
    buildingAge: '',
    boilerDisposition: '',
    boilerTechnology: '',
    boilerAge: '',
    boilerBrand: '',
    zohoId: '',
    leadType: ''
  },
  engieHomeService: {
    offer: '',
    boilerAge: '',
    customerAcceptation: '',
    zohoId: '',
    leadType: ''
  },
  myChauffage: {
    isOwner: '',
    installationType: '',
    installationConditionValidated: '',
    firstCustomerAgreement: '',
    secondCustomerAgreement: '',
    boilerReason: '',
    zohoId: '',
    leadType: ''
  },
  leadTelecom: {
    type: '',
    callbackDate: '',
    callbackTime: '',
    zohoId: '',
    description: ''
  },
  surveillanceCamera: {
    type: '',
    callbackDate: '',
    callbackTime: '',
    zohoId: '',
    description: ''
  },
  assurance: {
    callbackDate: '',
    callbackTime: '',
    zohoId: '',
    professionalCategory: '',
    description: '',
    leadType: ''
  },
  nextories: {
    offerId: '',
    departureCountry: '',
    departureCity: '',
    departureZipcode: '',
    movingDate: '',
    movingDescription: '',
    zohoId: ''
  },
  leadYouStock: {
    zohoId: ''
  },
  muterLoger: {
    zohoId: '',
    departureCity: ''
  },
  leadTransferMuterLoger: {
    callbackDate: '',
    callbackTime: '',
    zohoId: '',
    description: '',
    moreThan10Employees: null
  },
  leadBank: {
    zohoId: '',
    callbackDate: '',
    callbackTime: ''
  },
  leadEnergyPro: {
    zohoId: '',
    callbackDate: '',
    callbackTime: '',
    commitmentEndDate: '',
    description: ''
  },
  leadEnergyProPlaceDesEnergies: {
    zohoId: '',
    commitmentEndDate: ''
  },
  leadGasBottle: {
    zohoId: ''
  },
  leadFloraAssurance: {
    zohoId: '',
    leadType: ''
  },
  leadYagoAssurance: {
    zohoId: '',
    leadType: ''
  },
  leadSantevetAssurance: {
    zohoId: '',
    leadType: ''
  },
  leadVerisureTelecomBe: {
    zohoId: '',
    leadType: ''
  },
  leadBoilerEneco: {
    zohoId: '',
    leadType: ''
  },
  leadBoilerHomeserve: {
    zohoId: '',
    leadType: ''
  },
  leadPartnership: {
    zohoId: '',
    callbackDate: '',
    callbackTime: '',
    description: ''
  },
  leadProxiserve: {
    zohoId: '',
    leadType: ''
  },
  leadWater: {
    zohoId: ''
  },
  mesAllocs: {
    zohoId: ''
  },
  leadGroupPurchase: {
    zohoId: ''
  },
  leadAssistancePro: {
    zohoId: ''
  },
  edfInteraction: {
    zohoId: ''
  },
  leadEnergyRenovation: {
    zohoId: '',
    callbackDate: '',
    callbackTime: '',
    motivation: '',
    description: ''
  },
  proxiserveRadiator: {
    zohoId: '',
    leadType: ''
  },
  proxiserveWaterHeater: {
    zohoId: '',
    leadType: ''
  },
  leadMonConseillerPerso: {
    zohoId: '',
    offer: '',
    partnerId: '',
    errorFetchingPartnerId: ''
  },
  leadAssuranceEmprunteur: {
    zohoId: '',
    leadType: '',
    isEligible: ''
  },
  leadBankSG: {
    zohoId: ''
  },
  leadIsolation: {
    zohoId: ''
  },
  leadChargingPoint: {
    zohoId: '',
    chargingStationProvider: ''
  },
  leadEkwateurSolarKit: {
    zohoId: ''
  },
  leadSolarPanelsOtovoTE: {
    zohoId: '',
    leadType: ''
  },
  leadComparateur: {
    callbackDate: '',
    callbackTime: '',
    zohoId: '',
    leadType: '',
    description: ''
  },
  leadProxiservePAC: {
    zohoId: ''
  },
  leadVerisure: {
    zohoId: ''
  },
  leadTucoenergie: {
    zohoId: '',
    typeCivility: '',
    typeOccupation: '',
    typeRequest: '',
    leadStatus: ''
  },
  leadOffshoreEnergie: {
    zohoId: '',
    currentProvider: ''
  },
  leadOffshoreTelecom: {
    zohoId: '',
    currentProvider: ''
  },
  leadAssurancePro: {
    zohoId: '',
    callbackDate: '',
    callbackTime: ''
  },
  telecomComparator: {
    callbackDate: '',
    callbackTime: '',
    zohoId: '',
    leadType: '',
    description: ''
  },
  telecomEnergy: {
    callbackDate: '',
    callbackTime: '',
    zohoId: '',
    leadType: '',
    description: ''
  },
  telecomFree: {
    callbackDate: '',
    callbackTime: '',
    zohoId: '',
    leadType: '',
    description: ''
  },
  telecomSat: {
    callbackDate: '',
    callbackTime: '',
    zohoId: '',
    leadType: '',
    description: ''
  },
  axaAssurance: {
    zohoId: '',
    leadType: '',
    imei: '',
    deviceStatus: '',
    productId: '',
    axaEmailAlreadySent: false
  },
  assuranceProTelco: {
    zohoId: '',
    productId: '',
    callbackDate: '',
    callbackTime: ''
  },
  verisureTelecom: {
    zohoId: '',
    productId: ''
  },
  protected: {
    zohoId: '',
    productId: '',
    invoiceFrequency: '',
    smsCode: '',
    statusCode: null
  },
  accessoriesSfr: {
    zohoId: '',
    product: '',
    productId: '',
    saleId: ''
  },
  leadVoltalisBizdev: {
    zohoId: '',
    customerAgreement: '',
    recapAgreement: ''
  },
  leadCresus: {
    zohoId: ''
  }
}

export default additionalSalesDefaultState
