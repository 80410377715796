import additionalSalesDefaultState from './additionalSalesDefaultState'

const mapAdditionalSalesData = viewParameters => {
  const currentSubscriber = viewParameters.current_subscriber
  return {
    ...additionalSalesDefaultState,
    verisureTelecom: retrieveVerisureTelecomData(currentSubscriber),
    assistanceOptions: retrieveAssistanceOptionData(currentSubscriber),
    carbonOffset: retrieveCarbonOffsetData(currentSubscriber),
    voltalis: retrieveVoltalisData(currentSubscriber),
    telecomComparator: mapGenericLeadWithCallback(currentSubscriber?.sale_telecom_comparator),
    telecomFree: mapGenericLeadWithCallback(currentSubscriber?.sale_telecom_free),
    telecomEnergy: mapGenericLeadWithCallback(currentSubscriber?.sale_telecom_energy),
    telecomSat: mapGenericLeadWithCallback(currentSubscriber?.sale_telecom_sat),
    surveillanceCamera: retrieveSurveillanceCameraData(currentSubscriber),
    protected: retrieveProtectedData(currentSubscriber),
    assuranceProTelco: retrieveAssuranceProTelcoData(currentSubscriber),
    assurance: retrieveAssuranceData(currentSubscriber),
    axaAssurance: retrieveAxaAssuranceData(currentSubscriber),
    leadOffshoreEnergie: retrieveLeadOffshoreData(currentSubscriber?.sale_offshore_energie),
    leadOffshoreTelecom: retrieveLeadOffshoreData(currentSubscriber?.sale_offshore_telecom),
    leadPartnership: mapGenericLeadWithCallback(currentSubscriber?.sale_partnership),
    leadEnergyPro: retrieveLeadEnergyProData(currentSubscriber?.sale_energy_pro),
    leadBank: retrieveBankData(currentSubscriber),
    leadTelecom: retrieveLeadTelecomData(currentSubscriber),
    leadComparateur: retrieveLeadComparateurData(currentSubscriber),
    leadTransferMuterLoger: retrieveTransferMuterLogerData(currentSubscriber),
    leadMonConseillerPerso: retrieveMonConseillerPersoData(currentSubscriber),
    myChauffage: retrieveMyChauffageData(currentSubscriber),
    leadVerisure: simpleZohoIdMapping(currentSubscriber?.sale_verisure),
    leadProxiservePAC: simpleZohoIdMapping(currentSubscriber?.sale_proxiserve_p_a_c),
    accessoriesSfr: retrieveAccessoriesSfrData(currentSubscriber),
    leadIsolation: simpleZohoIdMapping(currentSubscriber?.sale_isolation),
    leadEkwateurSolarKit: simpleZohoIdMapping(currentSubscriber?.sale_ekwateur_solar_kit),
    leadGroupPurchase: simpleZohoIdMapping(currentSubscriber?.sale_group_purchase),
    leadAssistancePro: simpleZohoIdMapping(currentSubscriber?.sale_assistance_pro),
    leadGasBottle: simpleZohoIdMapping(currentSubscriber?.sale_gas_bottle),
    leadFloraAssurance: simpleLeadTypeAndZohoIdMappingNewStructure(currentSubscriber?.sale_flora_assurance),
    leadYagoAssurance: simpleLeadTypeAndZohoIdMappingNewStructure(currentSubscriber?.sale_yago_assurance),
    leadSantevetAssurance: simpleLeadTypeAndZohoIdMappingNewStructure(currentSubscriber?.sale_sante_vet_assurance),
    leadVerisureTelecomBe: simpleLeadTypeAndZohoIdMappingNewStructure(currentSubscriber?.sale_verisure_be),
    leadBoilerEneco: simpleLeadTypeAndZohoIdMappingNewStructure(currentSubscriber?.sale_boiler_eneco),
    leadBoilerHomeserve: simpleLeadTypeAndZohoIdMappingNewStructure(currentSubscriber?.sale_boiler_homeserve),
    mesAllocs: simpleZohoIdMapping(currentSubscriber?.sale_administrative_service),
    edfInteraction: interactionData(currentSubscriber?.edf_interaction ?? ''),
    leadWater: interactionData(currentSubscriber?.water_interaction ?? ''),
    leadTucoenergie: retrieveTucoenergieData(currentSubscriber),
    leadAssuranceEmprunteur: simpleLeadTypeAndZohoIdMapping(currentSubscriber?.sale_assurance_emprunteur),
    leadProxiserve: simpleLeadTypeAndZohoIdMapping(currentSubscriber?.sale_boiler_proxiserve),
    proxiserveRadiator: simpleLeadTypeAndZohoIdMapping(currentSubscriber?.sale_energy_renovation_radiator),
    proxiserveWaterHeater: simpleLeadTypeAndZohoIdMapping(currentSubscriber?.sale_energy_renovation_water_heater),
    leadBankSG: simpleZohoIdMapping(currentSubscriber?.sale_bank_s_g),
    leadSolarPanelsOtovoTE: simpleLeadTypeAndZohoIdMapping(currentSubscriber?.sale_solar_panels_otovo_t_e),
    nextories: retrieveNextoriesData(currentSubscriber),
    leadYouStock: simpleZohoIdMapping(currentSubscriber?.you_stock),
    muterLoger: retrieveMuterLogerData(currentSubscriber),
    leadEnergyRenovation: retrieveLeadEnergyRenovationData(currentSubscriber),
    leadChargingPoint: retrieveLeadChargingPointData(currentSubscriber),
    leadAssurancePro: retrieveAssuranceProData(currentSubscriber),
    engieHomeService: retrieveEngieHomeServiceData(currentSubscriber),
    garanka: retrieveGarankaData(currentSubscriber),
    leadVoltalisBizdev: retrieveLeadVoltalisBizdevData(currentSubscriber),
    leadCresus: retrieveLeadCresusData(currentSubscriber),
    leadEnergyProPlaceDesEnergies: retrieveLeadEnergyProPlaceDesEnergiesData(currentSubscriber),
  }
}

export const retrieveLeadEnergyProPlaceDesEnergiesData = currentSubscriber => {
  return {
    zohoId: currentSubscriber?.sale_energy_pro_place_des_energies?.zoho_id ?? '',
    commitmentEndDate: currentSubscriber?.sale_energy_pro_place_des_energies?.attributes?.commitmentEndDate ?? '',
  }
}

export const retrieveLeadVoltalisBizdevData = currentSubscriber => {
  return {
    zohoId: currentSubscriber?.sale_voltalis_bizdev?.zoho_id ?? '',
    customerAgreement: currentSubscriber?.sale_voltalis_bizdev?.attributes?.customerAgreement ?? '',
    recapAgreement: currentSubscriber?.sale_voltalis_bizdev?.attributes?.recapAgreement ?? '',
  }
}

export const retrieveLeadCresusData = currentSubscriber => {
  return {
    zohoId: currentSubscriber?.sale_cresus?.zoho_id ?? '',
  }
}

export const retrieveGarankaData = currentSubscriber => {
  return {
    zohoId: currentSubscriber?.sale_boiler_garanka?.zoho_id ?? '',
    leadType: currentSubscriber?.sale_boiler_garanka?.lead_type ?? '',
    customerAgreement: '',
    offerId: '',
    buildingType: '',
    buildingAge: '',
    boilerDisposition: '',
    boilerTechnology: '',
    boilerAge: '',
    boilerBrand: '',
  }
}

export const retrieveEngieHomeServiceData = currentSubscriber => {
  return {
    zohoId: currentSubscriber?.sale_boiler_engie_home_service?.zoho_id ?? '',
    leadType: currentSubscriber?.sale_boiler_engie_home_service?.lead_type ?? '',
    offer: '',
    boilerAge: '',
    customerAcceptation: '',
  }
}

export const retrieveAssuranceProData = currentSubscriber => {
  let callBackDate = ''
  let callBackTime = ''
  if (currentSubscriber?.sale_assurance_pro?.callback) {
    ;[callBackDate, callBackTime] = currentSubscriber.sale_assurance_pro.callback.split(' ')
  }

  return {
    zohoId: currentSubscriber?.sale_assurance_pro?.zoho_id ?? '',
    callbackDate: callBackDate,
    callbackTime: callBackTime,
  }
}

export const retrieveLeadChargingPointData = currentSubscriber => {
  return {
    zohoId: currentSubscriber?.sale_charging_point?.zoho_id ?? '',
    chargingStationProvider: currentSubscriber?.sale_charging_point?.attributes?.chargingStationProvider ?? '',
  }
}

export const retrieveLeadEnergyRenovationData = currentSubscriber => {
  return {
    ...mapGenericLeadWithCallback(currentSubscriber?.sale_energy_renovation_default),
    motivation: currentSubscriber?.sale_energy_renovation_default?.motivation ?? '',
  }
}

export const retrieveMuterLogerData = currentSubscriber => {
  return {
    zohoId: currentSubscriber?.sale_moving_muter_loger?.zoho_id ?? '',
    departureCity: currentSubscriber?.sale_moving_muter_loger?.departure_city ?? '',
  }
}

export const retrieveNextoriesData = currentSubscriber => {
  return {
    offerId: currentSubscriber?.sale_moving_nextories?.offer_id ?? '',
    departureCountry: currentSubscriber?.sale_moving_nextories?.departure_country ?? '',
    departureCity: currentSubscriber?.sale_moving_nextories?.departure_city ?? '',
    departureZipcode: currentSubscriber?.sale_moving_nextories?.departure_zipcode ?? '',
    movingDate: currentSubscriber?.sale_moving_nextories?.moving_date ?? '',
    movingDescription: currentSubscriber?.sale_moving_nextories?.moving_description ?? '',
    zohoId: currentSubscriber?.sale_moving_nextories?.zoho_id ?? '',
  }
}

export const simpleLeadTypeAndZohoIdMappingNewStructure = model => {
  if (!model) {
    return {
      zohoId: '',
      leadType: '',
    }
  }

  return {
    zohoId: model.zoho_contract_detail_id ?? '',
    leadType: model.product?.name ?? '',
  }
}

export const simpleLeadTypeAndZohoIdMapping = model => {
  if (!model) {
    return {
      zohoId: '',
      leadType: '',
    }
  }

  return {
    zohoId: model.zoho_id ?? '',
    leadType: model.lead_type ?? '',
  }
}

export const retrieveTucoenergieData = currentSubscriber => {
  return {
    zohoId: currentSubscriber?.sale_tucoenergie?.zoho_id ?? '',
    typeCivility: currentSubscriber?.sale_tucoenergie?.attributes?.typeCivility ?? '',
    typeOccupation: currentSubscriber?.sale_tucoenergie?.attributes?.typeOccupation ?? '',
    typeRequest: currentSubscriber?.sale_tucoenergie?.attributes?.typeRequest ?? '',
    leadStatus: currentSubscriber?.sale_tucoenergie?.status_code ?? '',
  }
}

export const interactionData = zohoId => {
  return {
    zohoId: zohoId ?? '',
  }
}

export const simpleZohoIdMapping = model => {
  return {
    zohoId: model?.zoho_id ?? '',
  }
}

export const retrieveMyChauffageData = currentSubscriber => {
  return {
    zohoId: currentSubscriber?.sale_boiler_my_chauffage?.zoho_id ?? '',
    leadType: currentSubscriber?.sale_boiler_my_chauffage?.lead_type ?? '',
    isOwner: '',
    installationType: '',
    installationConditionValidated: '',
    firstCustomerAgreement: '',
    secondCustomerAgreement: '',
    boilerReason: '',
  }
}

export const retrieveMonConseillerPersoData = currentSubscriber => {
  return {
    zohoId: currentSubscriber?.sale_selectra_service?.zoho_id ?? '',
    offer: currentSubscriber?.sale_selectra_service?.selectra_service_id ?? '',
    partnerId: currentSubscriber?.sale_selectra_service?.partner_id ?? '',
    statusCode: currentSubscriber?.sale_selectra_service?.status_code ?? '',
    errorFetchingPartnerId: currentSubscriber?.sale_selectra_service?.error_partner_sync ?? '',
  }
}

export const retrieveTransferMuterLogerData = currentSubscriber => {
  return {
    ...mapGenericLeadWithCallback(currentSubscriber?.sale_moving_transfer_muter_loger),
    moreThan10Employees: null,
  }
}

export const retrieveLeadTelecomData = currentSubscriber => {
  return {
    ...mapGenericLeadWithCallback(currentSubscriber?.sale_telecom),
    type: currentSubscriber?.sale_telecom?.lead_type ?? '',
  }
}

export const retrieveLeadComparateurData = currentSubscriber => {
  return {
    ...mapGenericLeadWithCallback(currentSubscriber?.sale_comparateur),
    type: currentSubscriber?.sale_comparateur?.attributes?.leadType ?? '',
    description: currentSubscriber?.sale_comparateur?.attributes?.description ?? '',
  }
}

export const retrieveBankData = currentSubscriber => {
  let callbackDate = ''
  let callbackTime = ''
  if (currentSubscriber?.sale_bank?.callback) {
    ;[callbackDate, callbackTime] = currentSubscriber.sale_bank.callback.split(' ')
  }
  return {
    zohoId: currentSubscriber?.sale_bank?.zoho_id ?? '',
    callbackDate: callbackDate ?? '',
    callbackTime: callbackTime ?? '',
  }
}

export const retrieveLeadOffshoreData = currentModel => {
  return {
    zohoId: currentModel?.zoho_id ?? '',
    currentProvider: currentModel?.attributes?.current_provider ?? '',
  }
}

export const retrieveAxaAssuranceData = currentSubscriber => {
  return {
    zohoId: currentSubscriber?.sale_axa_assurance?.zoho_id ?? '',
    leadType: currentSubscriber?.sale_axa_assurance?.product?.name ?? '',
    imei: currentSubscriber?.sale_axa_assurance?.attributes?.imei ?? '',
    deviceStatus: currentSubscriber?.sale_axa_assurance?.attributes?.deviceStatus ?? '',
    productId: currentSubscriber?.sale_axa_assurance?.attributes?.productId ?? '',
    axaEmailAlreadySent: currentSubscriber?.sale_axa_assurance?.attributes?.emailSent ?? false,
  }
}

export const retrieveAssuranceData = currentSubscriber => {
  return {
    ...mapGenericLeadWithCallback(currentSubscriber?.sale_assurance_default),
    leadType: currentSubscriber?.sale_assurance_default?.lead_type ?? '',
    professionalCategory: '',
  }
}

export const retrieveAssuranceProTelcoData = currentSubscriber => {
  let callBackDate = ''
  let callBackTime = ''
  if (currentSubscriber?.sale_assurance_pro_telco?.callback) {
    ;[callBackDate, callBackTime] = currentSubscriber.sale_assurance_pro_telco.callback.split(' ')
  }

  return {
    zohoId: currentSubscriber?.sale_assurance_pro_telco?.zoho_id ?? '',
    productId: currentSubscriber?.sale_assurance_pro_telco?.product_id ?? '',
    callbackDate: callBackDate,
    callbackTime: callBackTime,
  }
}

export const retrieveProtectedData = currentSubscriber => {
  return {
    zohoId: currentSubscriber?.sale_protected?.zoho_id ?? '',
    invoiceFrequency: currentSubscriber?.sale_protected?.attributes?.invoiceFrequency ?? '',
    smsCode: currentSubscriber?.sale_protected?.attributes?.code ?? '',
    productId: currentSubscriber?.sale_protected?.product_id ?? '',
    statusCode: currentSubscriber?.sale_protected?.status_code ?? null,
  }
}

export const retrieveSurveillanceCameraData = currentSubscriber => {
  return {
    ...mapGenericLeadWithCallback(currentSubscriber?.sale_surveillance_camera),
    type: currentSubscriber?.sale_surveillance_camera?.lead_type ?? '',
  }
}

export const retrieveVoltalisData = currentSubscriber => {
  return {
    zohoId: currentSubscriber?.sale_connected_devices?.zoho_id ?? '',
    heaterCount: currentSubscriber?.sale_connected_devices?.heater_count ?? '',
    callbackDate: currentSubscriber?.sale_connected_devices?.callback_date ?? '',
    callbackDetail: currentSubscriber?.sale_connected_devices?.callback_detail ?? '',
    constructionYear: currentSubscriber?.sale_connected_devices?.construction_year ?? '',
    isMainResidence: '',
    isHeatedElectricity: '',
    isMoreThan1Radiator: '',
    customerCallbackAgreement: '',
    customerInstallationAgreement: '',
  }
}

export const retrieveVerisureTelecomData = currentSubscriber => {
  return {
    zohoId: currentSubscriber?.sale_verisure_telecom?.zoho_id ?? '',
    productId: currentSubscriber?.sale_verisure_telecom?.product_id ?? '',
  }
}

export const retrieveAssistanceOptionData = currentSubscriber => {
  return {
    zohoId: currentSubscriber?.sale_assistance_option_id?.zoho_id ?? '',
    assistanceId: currentSubscriber?.sale_assistance_option_id?.assistance_id ?? '',
    customerAcceptation: '',
    axaEmailAlreadySent: currentSubscriber?.sale_assistance_option_id?.email_sent ?? false,
    delayActivation: currentSubscriber?.sale_assistance_option_id?.delay_activation_at ?? '',
  }
}

export const retrieveCarbonOffsetData = currentSubscriber => {
  return {
    zohoId:
      currentSubscriber?.sale_carbon_offset?.zoho_id ??
      currentSubscriber?.sale_carbon_offset?.zoho_contract_detail_id ??
      '',
    offsetId: currentSubscriber?.sale_carbon_offset?.carbon_offset_id ?? '',
    customerAcceptation: '',
  }
}

export const retrieveAccessoriesSfrData = currentSubscriber => {
  return {
    zohoId: currentSubscriber?.sale_accessories_sfr?.zoho_id ?? '',
    product: currentSubscriber?.sale_accessories_sfr?.attributes?.product ?? '',
    saleId: currentSubscriber?.sale_accessories_sfr?.attributes?.saleId ?? '',
    productId: currentSubscriber?.sale_accessories_sfr?.product_id ?? '',
  }
}

export const retrieveLeadEnergyProData = sale => {
  if (!sale) {
    return {
      zohoId: '',
      callbackDate: '',
      callbackTime: '',
      description: '',
      commitmentEndDate: '',
    }
  }

  let callBackDate = ''
  let callBackTime = ''
  if (sale.callback) {
    ;[callBackDate, callBackTime] = sale.callback.split(' ')
  }

  return {
    zohoId: sale.zoho_id ?? '',
    callbackDate: callBackDate,
    callbackTime: callBackTime,
    description: sale.description ?? '',
    commitmentEndDate: sale.attributes?.commitmentEndDate ?? '',
  }
}

export const mapGenericLeadWithCallback = model => {
  if (!model) {
    return {
      zohoId: '',
      callbackDate: '',
      callbackTime: '',
      description: '',
    }
  }

  let callBackDate = ''
  let callBackTime = ''
  if (model.callback) {
    ;[callBackDate, callBackTime] = model.callback.split(' ')
  }
  return {
    zohoId: model.zoho_id ?? '',
    leadType: model.product?.name ?? '',
    callbackDate: callBackDate,
    callbackTime: callBackTime,
    description: model.description ?? '',
  }
}

export default mapAdditionalSalesData
